import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({
  title,
  description,
  image,
  imageAlt,
  article,
  noIndex,
  canonical,
  metaTags,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultImageAlt,
  } = site.siteMetadata

  const seoObj = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defaultImage}`,
    imageAlt: imageAlt || defaultImageAlt,
    url: canonical || `${siteUrl}${pathname || ""}`,
    metaTags: metaTags || "Workgrid, digital workplace, enterprise software",
  }

  return (
    <Helmet
      htmlAttributes={{ lang: "en-US" }}
      title={seoObj.title}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={seoObj.description} />
      <meta name="image" content={seoObj.image} />

      {seoObj.url && <meta property="og:url" content={seoObj.url} />}

      {article && <meta property="og:type" content="article" />}

      {seoObj.title && <meta property="og:title" content={seoObj.title} />}

      {seoObj.description && (
        <meta property="og:description" content={seoObj.description} />
      )}

      {seoObj.image && (
        <meta name="image" property="og:image" content={seoObj.image} />
      )}

      {metaTags && <meta property="keywords" content={metaTags} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seoObj.title && <meta name="twitter:title" content={seoObj.title} />}

      {seoObj.description && (
        <meta name="twitter:description" content={seoObj.description} />
      )}

      {seoObj.image && <meta name="twitter:image" content={seoObj.image} />}

      {!noIndex && <meta name="robots" content="index,follow" />}
      {!noIndex && <meta name="googlebot" content="index,follow" />}

      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}

export default Seo

const query = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultImageAlt: imageAlt
        twitterUsername
      }
    }
  }
`
